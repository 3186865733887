<template>
  <div>
    <form-wizard
      color="#EF5329"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Salva"
      back-button-text="Precedente"
      next-button-text="Prosegui"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- tab 1 -->
      <tab-content
        title="Azienda"
        :before-change="validationForm"
      >
        <validation-observer
          ref="aziendaRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Seleziona Azienda
              </h5>
              <small class="text-muted">
                prego seleziona l'azienda per la quale aprire la nuova richiesta
              </small>
            </b-col>
            
            <b-col
              cols="12"
              class="mb-4"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Info Azienda"
                  rules="required"
                >
                <vue-autosuggest
                  ref="autocomplete"
                  v-model="query"
                  :suggestions="suggestions"
                  :input-props="inputProps"
                  :state="errors.length > 0 ? false:null"
                  :section-configs="sectionConfigs"
                  :render-suggestion="renderSuggestion"
                  :get-suggestion-value="getSuggestionValue"
                  @input="fetchResults"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-card-text class="mt-1 ml-1" v-if="this.infoAziendaSelezionata">
                sede in {{ this.infoAziendaSelezionata.comune }} ({{ this.infoAziendaSelezionata.provincia }})
              </b-card-text>
            </b-col>
          
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- tab 2 -->
      <tab-content
        title="Tipo di Richiesta"
        :before-change="validationFormTipoRichiesta"
      >
        <validation-observer
          ref="tipoRichiestaRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-4"
            >
              <b-form-group label="Tipo di Richiesta" label-for="id_tipo">
                  <validation-provider
                  #default="{ errors }"
                  name="Tipo di Richiesta"
                  rules="required"
                  >
                  <b-form-select
                      v-model="campiform.id_tipo"
                      @change="onChange($event)"
                      :options="options"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
            
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- tab 3 -->
      <tab-content
        title="Inserimento Informazioni"
        :before-change="validationFormInserimentoDati"
      >
        <validation-observer
          ref="inserimentoDatiRules"
          tag="form"
        >
          <div v-if="this.campiform.id_tipo == 7">
            <b-row>
              <b-col
                cols="12"
                class="mb-4"
              >
                <b-card bg-variant="Default"
                  v-for="(riga,index) in struttura_campi_scheda"
                  :key="index"
                >
                  <h4 v-if="riga.step_titolo">{{ riga.step_titolo }}</h4>

                  <div v-if="index == 1">
                    <b-form-group label="Denominazione" label-for="titolo">
                        <validation-provider
                        #default="{ errors }"
                        name="Titolo"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.titolo"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Indirizzo" label-for="indirizzo">
                        <validation-provider
                        #default="{ errors }"
                        name="indirizzo"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.indirizzo"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Comune" label-for="autocomplete_comune">
                      <validation-provider
                          #default="{ errors }"
                          name="Selezione Comune"
                          rules="required"
                        >
                        <vue-autosuggest
                          ref="autocomplete_comune"
                          v-model="query_comune"
                          :suggestions="suggestions_comune"
                          :input-props="inputProps_comune"
                          :state="errors.length > 0 ? false:null"
                          :section-configs="sectionConfigs_comune"
                          :render-suggestion="renderSuggestion"
                          :get-suggestion-value="getSuggestionValue"
                          @input="fetchResults_comune"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Note" label-for="note">
                        <b-form-textarea
                            v-model="campiform.note"
                            placeholder="note sulla scheda del sopraluogo"
                            rows="3"
                        />
                    </b-form-group>
                  </div>

                  <div v-for="(row,indice) in riga.campi"
                  :key="indice"
                  class="mb-2"
                  >
                    <b-form-group v-if="row.tipo_campo == 'text'" :label="row.nome" :label-for="row.nome">
                      <validation-provider v-if="row.obbligatorio == '1'"
                      #default="{ errors }"
                      :name="row.nome"
                      rules="required"
                      >
                      <b-form-input
                          v-model="campiform.sopraluogo[row.id]"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="row.placeholder"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <b-form-input v-if="row.obbligatorio == '0'"
                          v-model="campiform.sopraluogo[row.id]"
                          :placeholder="row.placeholder"
                      />
                    </b-form-group>

                    <b-form-group v-if="row.tipo_campo == 'photo'" :label="row.nome" :label-for="row.nome">
                      <validation-provider v-if="row.obbligatorio == '1'"
                      #default="{ errors }"
                      :name="row.nome"
                      rules="required"
                      >
                      <b-form-file
                          accept="image/*"
                          v-model="foto[row.nome_campo]"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="row.placeholder"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <b-form-file v-if="row.obbligatorio == '0'"
                          accept="image/*"
                          v-model="foto[row.nome_campo]"
                          :placeholder="row.placeholder"
                      />
                    </b-form-group>

                  </div>
                </b-card>

              </b-col>           
            </b-row>
          </div>

          <div v-else>
            <b-row>
              <b-col
                cols="12"
                class="mb-4"
              >
                <b-form-group label="Titolo della Richiesta" label-for="titolo">
                    <validation-provider
                    #default="{ errors }"
                    name="Titolo"
                    rules="required"
                    >
                    <b-form-input
                        v-model="campiform.titolo"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Messaggio / Note" label-for="note">
                    <b-form-textarea
                        v-model="campiform.note"
                        placeholder="Descrivi brevemente la tua richiesta"
                        rows="3"
                    />
                </b-form-group>
              </b-col>            
            </b-row>
          </div>
        </validation-observer>
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>
import axios from '@axios'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCard, 
  BCardText, 
  BFormSelect, 
  BFormTextarea, 
  BFormFile, 
} from 'bootstrap-vue'
import { required, email } from '@validations'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    VueAutosuggest,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BCard,
    BCardText,
    BFormSelect,
    BFormTextarea, 
    BFormFile, 
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      campiform: {
        id_tipo: '',
        titolo: '',
        indirizzo: '', 
        note: '', 
        sopraluogo: [],
      },
      foto: [],
      options: [{ value: null, text: 'seleziona una voce' },],
      query: '',
      query_comune: '',
      results: [],
      timeout: null,
      infoAziendaSelezionata: null,
      infoComuneSelezionato: null,
      debounceMilliseconds: 250,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Ricerca azienda per ragione sociale ...',
        class: 'form-control',
        name: 'ajax',
      },
      inputProps_comune: {
        id: 'autosuggest_comune__input_ajax',
        placeholder: 'Ricerca comune ...',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      suggestions_comune: [],
      sectionConfigs: {
        destinations: {
          onSelected: infoAziendaSelezionata => {
            console.log('infoAziendaSelezionata vvvv')
            console.log(infoAziendaSelezionata.item)

            this.infoAziendaSelezionata = infoAziendaSelezionata.item
          },
        }
      },
      sectionConfigs_comune: {
        destinations: {
          onSelected: infoComuneSelezionato => {
            console.log('sectionConfigs_comune vvvv')
            console.log(infoComuneSelezionato.item)

            this.infoComuneSelezionato = infoComuneSelezionato.item
          },
        }
      },
      required,
      userData: {},
      struttura_campi_scheda: [],
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))

  },
  methods: {
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        //console.log('cosa vuoi, speriamo bene ... '+query)

        return new Promise((resolve, reject) => {
          this.$http.get('v2/crmadmin/ricerca_aziende', {
            params: {
              cosa: query,
            }
          })
          .then(response => { 
              //console.log("risposta server --------")
              ///console.log(response.data.dati)

              this.suggestions = []
              this.infoAziendaSelezionata = null

              this.suggestions.push({ name: 'destinations', data: response.data.dati })
          })
          .catch(e => {
            console.log(e);
          })
        })
      }, this.debounceMilliseconds)
    },
    fetchResults_comune() {
      const { query_comune } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        //console.log('cosa vuoi, speriamo bene ... '+query)

        return new Promise((resolve, reject) => {
          this.$http.get('v2/crmadmin/ricerca_comune', {
            params: {
              cosa: query_comune,
            }
          })
          .then(response => { 
              //console.log("risposta server --------")
              ///console.log(response.data.dati)

              this.suggestions_comune = []
              this.infoComuneSelezionato = null

              this.suggestions_comune.push({ name: 'destinations', data: response.data.dati })
          })
          .catch(e => {
            console.log(e);
          })
        })
      }, this.debounceMilliseconds)
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.name
    },
    formSubmitted() {
      /*
      console.log("azienda selezionata vvvvvvv")
      console.log(this.infoAziendaSelezionata)
      console.log("altri dati del form vvvvvvv")
      console.log(this.campiform)
      */

      if(this.campiform.id_tipo == "7"){
        /*
        console.log('SALVATA DATI SUPRALUOGO TECNICO !!!!!!!!!!!!!!!!')
        console.dir(this.campiform.sopraluogo)
        console.dir('file test 1')
        console.dir(this.selectedFile)
        */

        console.dir('this.foto vvvvvvv')
        console.dir(this.foto)

        let formData = new FormData();

        //accodo FILE all'invio dati manualmente
        formData.append('file_foto_cartello', this.foto.foto_cartello);
        formData.append('file_foto_cartello_affisso', this.foto.foto_cartello_affisso);
        
        formData.append('file_foto_ambiente_1', this.foto.foto_ambiente_1);
        formData.append('file_foto_ambiente_2', this.foto.foto_ambiente_2);
        formData.append('file_foto_ambiente_3', this.foto.foto_ambiente_3);
        formData.append('file_foto_ambiente_4', this.foto.foto_ambiente_4);

        formData.append('file_foto_accesso', this.foto.foto_accesso);
        formData.append('file_foto_dislivello', this.foto.foto_dislivello);
        formData.append('file_foto_davanzale', this.foto.foto_davanzale);

        //accodo INFORMAZIONI TESTUALI all'invio
        formData.append('id_azienda', parseInt(this.infoAziendaSelezionata.id_azienda));
        formData.append('id_utente_admin', parseInt(this.userData.id));
        formData.append('id_tipo', parseInt(this.campiform.id_tipo));
        formData.append('id_comune', parseInt(this.infoComuneSelezionato.id_comune));
        formData.append('campiform', JSON.stringify(this.campiform));

        console.log("formData vvvvv")
        console.log(formData)

        axios.post('v2/crmadmin/salvanuovolead_sopraluogo', formData, {
          header:{
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => { 
          console.log("risposta server ------- sperimo bene ...")
          console.log(response.data)

          if(response.data.statusCode===200){
              //risposta positiva
              if((response.data.dati_risposta.id > 0) && (response.data.dati_risposta.id_azienda > 0)){
                //redirect verso dettaglio lead appena salvato
                this.$router.replace('/crm/leadinfo/'+response.data.dati_risposta.id+'/'+response.data.dati_risposta.id_azienda)
                  .then(() => {
                    this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                  })
              } else {
                //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                this.campiform = {}; //svuota i campi del form
                this.infoAziendaSelezionata = null; //svuota la selezione dell'azienda
                this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                
              }
              
            } else {
              //risposta negativa (errore sul server)
              this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')
            }

        })
        .catch(function(){
          this.showToast('danger','AlertCircleIcon','Errore salvataggio !!! - prego contattare l\'assistenza tecnica','','top-center')
        })

      } else {
        //tutte le altre tipologie di intervento

        //aggiungi ID_azienda al post in partenza
        this.campiform = Object.assign({}, this.campiform, {
          id_azienda: parseInt(this.infoAziendaSelezionata.id_azienda),
          id_utente_admin: parseInt(this.userData.id),
          id_tipo: parseInt(this.campiform.id_tipo)
        });

        //console.log(this.campiform);
        
        this.$http.post('v2/crmadmin/salvanuovolead', {
          dati_form: this.campiform 
          }).then(response => { 
            console.log("risposta server --------")
            console.log(response.data)

            if(response.data.statusCode===200){
              //risposta positiva
              if((response.data.dati_risposta.id > 0) && (response.data.dati_risposta.id_azienda > 0)){
                //redirect verso dettaglio lead appena salvato
                this.$router.replace('/crm/leadinfo/'+response.data.dati_risposta.id+'/'+response.data.dati_risposta.id_azienda)
                  .then(() => {
                    this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                  })
              } else {
                //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                this.campiform = {}; //svuota i campi del form
                this.infoAziendaSelezionata = null; //svuota la selezione dell'azienda
                this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                
              }
              
            } else {
              //risposta negativa (errore sul server)
              this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')
            }
          }).catch(e => {
            console.log(e);
          });
      }

      /*
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted -> '+this.infoAziendaSelezionata.name,
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      */
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.aziendaRules.validate().then(success => {
          if (success) {
            resolve(true)

            //una volta validato lo step 1 => carico i dati per lo step 2
            return new Promise((resolve, reject) => {
              this.$http.get('v2/crmadmin/tipi_leads').then(response => { 
                  //console.log("risposta server --------")
                  ///console.log(response.data.dati)

                  if(response.status===200){
                    this.options = response.data.dati_risposta

                    //seleziono nel select la prima voce restiuita
                    //this.campiform.id_tipo = response.data.dati_risposta[0].value
                  } else {
                    this.showToast('danger','AlertCircleIcon','Errore nel caricamento dei Tipi di Richiesta, prego contattare la nostra assistenza','','top-center')
                  }
              })
              .catch(e => {
                console.log(e);
              })
            });
          } else {
            reject()
          }
        })
      })
    },
    onChange(event) {
        console.log("speriamo bene ... "+this.campiform.id_tipo)

        /*
        //in caso di selezione "richiesta preventivo => visualizza l'area dove ci sarà la tabella"
        if(this.campiform.selected == 6){

          this.area_select_articolo_preventivo = true
          this.campiform.titolo = 'Richiesta preventivo per i seguenti Articoli'
          
          //richiesta dati lista prodotti per i quali richiedere un preventivo          
          this.$http.get('v2/articoli/lista_articoli_per_selezione/')
            .then(response => { 
                console.log(response.data.dati);

                this.rows = response.data[1].righe
            })
        } else {
          //nascondi area
          this.area_select_articolo_preventivo = false
          //cancella titolo
          this.campiform.titolo = ''
        }
        */
    },
    validationFormTipoRichiesta() {
      return new Promise((resolve, reject) => {
        this.$refs.tipoRichiestaRules.validate().then(success => {
          if (success) {
            resolve(true)

            if(this.campiform.id_tipo == 7){
              console.log('estrazione struttura campi per "sopralugo"');

              //recupero struttura campi del form per sopraluogo
              return new Promise((resolve, reject) => {
                this.$http.get('v2/crmadmin/campi_sopraluogo?id='+this.userData.id).then(response => { 
                    //console.log("risposta server --------")
                    //console.log(response.data.dati_risposta)

                    if(response.status===200){
                      //salvataggio struttura campi sopraluogo
                      this.struttura_campi_scheda = response.data.dati_risposta
                    } else {
                      this.showToast('danger','AlertCircleIcon','Errore nel caricamento dei Tipi di Richiesta, prego contattare la nostra assistenza','','top-center')
                    }
                })
                .catch(e => {
                  console.log(e);
                })
              });
            }
          } else {
            reject()
          }
        })
      })
    },
    validationFormInserimentoDati() {
      return new Promise((resolve, reject) => {
        this.$refs.inserimentoDatiRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>


<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
