var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#EF5329","title":null,"subtitle":null,"shape":"square","finish-button-text":"Salva","back-button-text":"Precedente","next-button-text":"Prosegui"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Azienda","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"aziendaRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Seleziona Azienda ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" prego seleziona l'azienda per la quale aprire la nuova richiesta ")])]),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Info Azienda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{ref:"autocomplete",attrs:{"suggestions":_vm.suggestions,"input-props":_vm.inputProps,"state":errors.length > 0 ? false:null,"section-configs":_vm.sectionConfigs,"render-suggestion":_vm.renderSuggestion,"get-suggestion-value":_vm.getSuggestionValue},on:{"input":_vm.fetchResults},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(this.infoAziendaSelezionata)?_c('b-card-text',{staticClass:"mt-1 ml-1"},[_vm._v(" sede in "+_vm._s(this.infoAziendaSelezionata.comune)+" ("+_vm._s(this.infoAziendaSelezionata.provincia)+") ")]):_vm._e()],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Tipo di Richiesta","before-change":_vm.validationFormTipoRichiesta}},[_c('validation-observer',{ref:"tipoRichiestaRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo di Richiesta","label-for":"id_tipo"}},[_c('validation-provider',{attrs:{"name":"Tipo di Richiesta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.onChange($event)}},model:{value:(_vm.campiform.id_tipo),callback:function ($$v) {_vm.$set(_vm.campiform, "id_tipo", $$v)},expression:"campiform.id_tipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Inserimento Informazioni","before-change":_vm.validationFormInserimentoDati}},[_c('validation-observer',{ref:"inserimentoDatiRules",attrs:{"tag":"form"}},[(this.campiform.id_tipo == 7)?_c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},_vm._l((_vm.struttura_campi_scheda),function(riga,index){return _c('b-card',{key:index,attrs:{"bg-variant":"Default"}},[(riga.step_titolo)?_c('h4',[_vm._v(_vm._s(riga.step_titolo))]):_vm._e(),(index == 1)?_c('div',[_c('b-form-group',{attrs:{"label":"Denominazione","label-for":"titolo"}},[_c('validation-provider',{attrs:{"name":"Titolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.titolo),callback:function ($$v) {_vm.$set(_vm.campiform, "titolo", $$v)},expression:"campiform.titolo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Indirizzo","label-for":"indirizzo"}},[_c('validation-provider',{attrs:{"name":"indirizzo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.indirizzo),callback:function ($$v) {_vm.$set(_vm.campiform, "indirizzo", $$v)},expression:"campiform.indirizzo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Comune","label-for":"autocomplete_comune"}},[_c('validation-provider',{attrs:{"name":"Selezione Comune","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{ref:"autocomplete_comune",refInFor:true,attrs:{"suggestions":_vm.suggestions_comune,"input-props":_vm.inputProps_comune,"state":errors.length > 0 ? false:null,"section-configs":_vm.sectionConfigs_comune,"render-suggestion":_vm.renderSuggestion,"get-suggestion-value":_vm.getSuggestionValue},on:{"input":_vm.fetchResults_comune},model:{value:(_vm.query_comune),callback:function ($$v) {_vm.query_comune=$$v},expression:"query_comune"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Note","label-for":"note"}},[_c('b-form-textarea',{attrs:{"placeholder":"note sulla scheda del sopraluogo","rows":"3"},model:{value:(_vm.campiform.note),callback:function ($$v) {_vm.$set(_vm.campiform, "note", $$v)},expression:"campiform.note"}})],1)],1):_vm._e(),_vm._l((riga.campi),function(row,indice){return _c('div',{key:indice,staticClass:"mb-2"},[(row.tipo_campo == 'text')?_c('b-form-group',{attrs:{"label":row.nome,"label-for":row.nome}},[(row.obbligatorio == '1')?_c('validation-provider',{attrs:{"name":row.nome,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":row.placeholder},model:{value:(_vm.campiform.sopraluogo[row.id]),callback:function ($$v) {_vm.$set(_vm.campiform.sopraluogo, row.id, $$v)},expression:"campiform.sopraluogo[row.id]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(row.obbligatorio == '0')?_c('b-form-input',{attrs:{"placeholder":row.placeholder},model:{value:(_vm.campiform.sopraluogo[row.id]),callback:function ($$v) {_vm.$set(_vm.campiform.sopraluogo, row.id, $$v)},expression:"campiform.sopraluogo[row.id]"}}):_vm._e()],1):_vm._e(),(row.tipo_campo == 'photo')?_c('b-form-group',{attrs:{"label":row.nome,"label-for":row.nome}},[(row.obbligatorio == '1')?_c('validation-provider',{attrs:{"name":row.nome,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":"image/*","state":errors.length > 0 ? false:null,"placeholder":row.placeholder},model:{value:(_vm.foto[row.nome_campo]),callback:function ($$v) {_vm.$set(_vm.foto, row.nome_campo, $$v)},expression:"foto[row.nome_campo]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(row.obbligatorio == '0')?_c('b-form-file',{attrs:{"accept":"image/*","placeholder":row.placeholder},model:{value:(_vm.foto[row.nome_campo]),callback:function ($$v) {_vm.$set(_vm.foto, row.nome_campo, $$v)},expression:"foto[row.nome_campo]"}}):_vm._e()],1):_vm._e()],1)})],2)}),1)],1)],1):_c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Titolo della Richiesta","label-for":"titolo"}},[_c('validation-provider',{attrs:{"name":"Titolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.titolo),callback:function ($$v) {_vm.$set(_vm.campiform, "titolo", $$v)},expression:"campiform.titolo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Messaggio / Note","label-for":"note"}},[_c('b-form-textarea',{attrs:{"placeholder":"Descrivi brevemente la tua richiesta","rows":"3"},model:{value:(_vm.campiform.note),callback:function ($$v) {_vm.$set(_vm.campiform, "note", $$v)},expression:"campiform.note"}})],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }